import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import './index.css';
import App from "./App";

import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faGlasses, faCubes } from '@fortawesome/free-solid-svg-icons'
import * as serviceWorker from './serviceWorker';

library.add(fab, faGlasses, faCubes)

ReactDOM.render(
    <BrowserRouter>
    <App />
  </BrowserRouter>,

document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();