import React from 'react';
import "../styles/CaseStudies.css"
import AwsServices from "../assets/Amusema_AWS_ecs_services.png";
import Header from '../components/home/header-case-studies';
import Footer from '../components/home/footer';


export default function UsecaseIngomaEvents() {
    return (
        <>
        <Header />

            <section id="case-studies">
                <p><br/></p>

                <div className="container wow fadeIn">
                    <div className="row">
                        <div className="col-lg-9 text-center text-lg-left">
                            <h1 class="h1 cta-text">Ingoma Foundation NPO</h1>
                            <span class="badge badge-pill badge-info">Media and Entertainment</span>
                        </div>
                        <div className="col-lg-3 cta-btn-container text-center">

                            <a href="mailto:builders@umdoko.com" class="nav-link active">Let's help you build</a>
                        </div>
                    </div>
                </div>
                <p><br/></p>
                <div className="container">
                    <div className="row about-container">
                        <div className="col-lg-12 cta-text">
                            <h2 className="title">Who's Ingoma Foundation</h2>
                            <p>
                                The INGOMA Foundation, a registered NPO (166-619), champions the rise of emerging gospel artists across Africa. Since 2013, we’ve celebrated their talents through the Annual Independent National Gospel Music Awards (INGOMAs), fostering a vibrant community of gospel music excellence.
                            </p>
                        </div>

                    </div>
                </div>
                <p><br/></p>
                <div className="container">
                    <div className="row about-container">
                        <div className="col-lg-12 cta-text">
                            <h2 className="title">Challenges</h2>
                            <p>
                                Ingoma Foundation was searching for a more affordable and reliable ticketing platform to
                                manage their event ticket sales efficiently. Existing solutions were either too costly
                                or lacked essential features needed to streamline operations. Without a suitable
                                platform, they faced challenges such as high operational costs, inefficient ticket
                                distribution, and a poor user experience for both event organizers and attendees.
                            </p>
                        </div>

                    </div>
                </div>
                <p><br/></p>
                <div className="container">
                    <div className="row about-container">
                        <div className="col-lg-12 cta-text">
                            <h2 className="title">Solution</h2>
                            <p>
                                Amusema offers an affordable and easy-to-use ticketing platform for Ingoma Foundation.
                                It simplifies ticket sales, ensures secure transactions, and helps organizers manage
                                events efficiently. With a cloud-based system, it reduces costs and scales easily. By
                                choosing Amusema, Ingoma Foundation can run smoother, more cost-effective events.
                            </p>
                        </div>
                    </div>
                </div>
                <p><br/></p>
                <div className="container">
                    <div className="row about-container">
                        <div className="col-lg-12 cta-text">
                            <h2 className="title">AWS DevOps implementation</h2>
                            <p>To enhance efficiency, automate deployments, and improve scalability, the following AWS
                                services were implemented:</p>
                            <p>
                                <img src={AwsServices} alt="AWS Services" width="100%"/>

                            </p>
                        </div>

                    </div>
                </div>
                <p><br/></p>
                <div className="jumbotron  bg-light rounded">
                    <div className="container">
                        <h1 class="display-5">"Highly secure and optimized for speed"</h1>
                        <p class="lead">With it's solution (Amusema), Umdoko Internet demonstrated exceptional skill and
                            professionalism. They designed and developed our website with a focus on performance,
                            ensuring that it is both highly secure and optimized for speed. We were particularly
                            impressed by their ability to deliver a website that not only loads quickly but also handles
                            traffic efficiently, providing a seamless experience for our users.</p>
                        <footer class="blockquote-footer">Dr. Dan Raseluma, Chief Executive Officer, INGOMA Foundation
                        </footer>
                    </div>
                </div>

                <p><br/></p>
                <div className="container">
                    <div className="row about-container">
                        <div className="col-lg-12 cta-text">
                            <h2 className="title">Benefits</h2>
                            <p>
                                Reimagining event management to assist you in reaching desired goals more quickly.
                                Amusema leverage the power of technology to make event organiser's lives easier and
                                simplify their engagement with event attendees.
                            </p>
                            <p>Seamless Efficiency: Amusema streamlines the entire event management process, eliminating
                                the need for multiple tools. From planning to execution, experience unparalleled
                                efficiency as you effortlessly navigate through a user-friendly interface, saving time
                                and resources. </p>
                            <p>Simplicity: Amusema streamlines event planning, so you can focus on creating memorable
                                experiences instead of getting lost in details.</p>
                            <p>Innovation: With cutting-edge tools, Amusema bring fresh ideas and possibilities to your
                                events, making them stand out.</p>
                        </div>
                    </div>
                </div>

            </section>
            <Footer/>
        </>
    )
}