import React from 'react';
import "../styles/CaseStudies.css"
import AwsServices from "../assets/Amusema_AWS_ecs_services.png";
import Header from '../components/home/header-case-studies';
import Footer from '../components/home/footer';


export default function UsecaseDecathlonMidrand() {
    return (
        <>
        <Header />

            <section id="case-studies">
                <p><br/></p>

                <div className="container wow fadeIn">
                    <div className="row">
                        <div className="col-lg-9 text-center text-lg-left">
                            <h1 class="h1 cta-text">Decathlon Midrand</h1>
                            <span class="badge badge-pill badge-info">Retail & Consumer Goods</span>
                        </div>
                        <div className="col-lg-3 cta-btn-container text-center">

                            <a href="mailto:builders@umdoko.com" class="nav-link active">Let's help you build</a>
                        </div>
                    </div>
                </div>
                <p><br/></p>
                <div className="container">
                    <div className="row about-container">
                        <div className="col-lg-12 cta-text">
                            <h2 className="title">Who's Decathlon</h2>
                            <p>

                                DECATHLON, the leading company in the multisports retail market, operates in two main areas: the creation of sports products and innovative services. DECATHLON and its 105,000 employees have been working since 1976 to achieve a constant ambition: to innovate in all fields and remain the primary playing partner for all athletes.
                            </p>
                        </div>

                    </div>
                </div>
                <p><br/></p>
                <div className="container">
                    <div className="row about-container">
                        <div className="col-lg-12 cta-text">
                            <h2 className="title">Challenges</h2>
                            <p>
                                Decathlon Midrand is actively hosting a variety of sporting events and is currently
                                searching for a reliable, user-friendly, and cost-effective ticketing platform to
                                streamline event management and enhance the experience for participants and attendees.
                            </p>
                        </div>

                    </div>
                </div>
                <p><br/></p>
                <div className="container">
                    <div className="row about-container">
                        <div className="col-lg-12 cta-text">
                            <h2 className="title">Solution</h2>
                            <p>
                                The introduction of the Amusema ticketing platform is providing Decathlon Midrand with a
                                reliable and cost-effective solution for managing their sporting events.

                                With its seamless integration and automated CI/CD (Continuous Integration and Continuous
                                Deployment) pipeline, the platform ensures efficient updates, improved scalability, and
                                a smooth ticketing experience for both organisers and attendees.
                            </p>
                        </div>
                    </div>
                </div>
                <p><br/></p>
                <div className="container">
                    <div className="row about-container">
                        <div className="col-lg-12 cta-text">
                            <h2 className="title">AWS DevOps implementation</h2>
                            <p>To enhance efficiency, automate deployments, and improve scalability, the following AWS
                                services were implemented:</p>
                            <p>
                                <img src={AwsServices} alt="AWS Services" width="100%"/>

                            </p>
                        </div>

                    </div>
                </div>
                <p><br/></p>
                <div className="jumbotron  bg-light rounded">
                    <div className="container">
                        <h1 class="display-5">"Simplicity in event management"</h1>
                        <p class="lead">I love Amusema's simplicity — this platform truly delivers simplicity in event management.</p>
                        <footer class="blockquote-footer">Teboho Diboke, Midrand Event Coordinator & B2B Refrent
                        </footer>
                    </div>
                </div>

                <p><br/></p>
                <div className="container">
                    <div className="row about-container">
                        <div className="col-lg-12 cta-text">
                            <h2 className="title">Benefits</h2>
                            <p>
                                Reimagining event management to assist you in reaching desired goals more quickly.
                                Amusema leverage the power of technology to make event organiser's lives easier and
                                simplify their engagement with event attendees.
                            </p>
                            <p>Seamless Efficiency: Amusema streamlines the entire event management process, eliminating
                                the need for multiple tools. From planning to execution, experience unparalleled
                                efficiency as you effortlessly navigate through a user-friendly interface, saving time
                                and resources. </p>
                            <p>Simplicity: Amusema streamlines event planning, so you can focus on creating memorable
                                experiences instead of getting lost in details.</p>
                            <p>Innovation: With cutting-edge tools, Amusema bring fresh ideas and possibilities to your
                                events, making them stand out.</p>
                        </div>
                    </div>
                </div>

            </section>
            <Footer/>
        </>
    )
}